import { get, update } from '~/services/http-requests'

const _BS_ELITE_PAGE = 'elite_page'

export async function fetchElitePage(): Promise<IElitepage> {
    const [first] = await get({ baseProperty: _BS_ELITE_PAGE })
    return first
}

export function updateElitePage(producerId: IProducer['id'], elitepage: Partial<IElitepage>): Promise<IService> {
    return update({ baseProperty: _BS_ELITE_PAGE, fullUrl: `/api_producer/${_BS_ELITE_PAGE}/${producerId}/`, dispatchParams: elitepage, isFreeRequest: true })
}
