










































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'

// @ts-ignore
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import VLoadSpinner from '~/components/VLoadSpinner.vue'
import AlertBoxSubscription from '#/components/AlertBoxSubscription.vue'

import { UserStore } from '@/store/user'
import { ModalStore, ModalType, IModalParams } from '~/store/modal'
import { fetchElitePage, updateElitePage } from '#/api/elitepage'
import { youTubeGetVideoID, youTubeGetChannelFromUrl } from '~/utils/youtubeStrings'
import { ENV_IS_DEVELOPMENT, ENV_IS_STAGING } from '~/const/environment'

@Component({ components: { VLoadSpinner, AlertBoxSubscription } })
export default class Elitepage extends Vue {
	ModalType = ModalType
	elitepage: IElitepage = null
	isLoading: boolean = false
	urlStart = ENV_IS_DEVELOPMENT || ENV_IS_STAGING ? 'test-elite.thecharts.com' : 'elite.thecharts.com'

	@UserStore.State('authenticatedUser') authenticatedUser: IUserProducer
	@UserStore.Getter('hasEliteSubscription') hasEliteSubscription: boolean
	@ModalStore.Mutation('SET_ACTIVE_MODAL') SET_ACTIVE_MODAL: (modalParams: IModalParams) => void

	get elitepageLink() {
		return `https://${this.urlStart}/${this.authenticatedUser ? this.authenticatedUser.slug : ''}`
	}

	get mailingListPageLink() {
		return `https://${this.urlStart}/${this.authenticatedUser ? this.authenticatedUser.slug : ''}/mailing-list`
	}

	get productPage() {
		return `https://${this.urlStart}/${this.authenticatedUser ? this.authenticatedUser.slug : ''}/product`
	}

	get youtubeChannelId() {
		return this.elitepage.youtube_channel_id ? 'https://www.youtube.com/channel/' + this.elitepage.youtube_channel_id : ''
	}

	set youtubeChannelId(newValue: string) {
		updateElitePage(this.elitepage.producer_id, { youtube_channel_id: youTubeGetChannelFromUrl(newValue) })
	}

	get youtubeVideoLink() {
		return this.elitepage.youtube_video_id ? 'https://www.youtube.com/watch?v=' + this.elitepage.youtube_video_id : ''
	}

	set youtubeVideoLink(newValue: string) {
		updateElitePage(this.elitepage.producer_id, { youtube_video_id: youTubeGetVideoID(newValue) })
	}

	async updateElitePage() {
		try {
			await updateElitePage(this.elitepage.producer_id, {
				show_analytics: this.elitepage.show_analytics,
				facebook_pixel_id: this.elitepage.facebook_pixel_id,
				seo_title: this.elitepage.seo_title,
				seo_description: this.elitepage.seo_description,
				seo_keywords: this.elitepage.seo_keywords
			})
			this.SET_ACTIVE_MODAL({ modal: ModalType.Success })
		} catch (error) {
			console.error(error)
			this.SET_ACTIVE_MODAL({ modal: ModalType.Error })
		}
	}

	async loadData() {
		try {
			this.isLoading = true
			this.elitepage = await fetchElitePage()
		} catch (error) {
			console.error(error)
		} finally {
			this.isLoading = false
		}
	}

	async mounted() {
		this.loadData()
	}
}
